import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';
import styles from './radio.css';

function Radio({
  classes,
  style,
  label,
  onChange,
  small,
  error: { hasError },
  'data-testid': dataTestId,
  ...restInputProps
}) {
  const { disabled } = restInputProps;
  const radioClassName = classNames(styles.radio, {
    [styles.small]: small,
    [styles.error]: hasError,
  });
  const labelClassName = classNames(styles.label, classes.label, {
    [Typography.legalBody]: small,
    [Typography.body1]: !small,
    [styles.disabled]: disabled,
  });

  return (
    <label
      className={classNames(styles.root, classes.root)}
      style={style}
      onChange={onChange}
      data-testid={dataTestId}
    >
      <input
        className={radioClassName}
        type='radio'
        onChange={() => {}}
        {...restInputProps}
      />

      <span className={labelClassName}>{label}</span>
    </label>
  );
}

Radio.defaultProps = {
  classes: {},
  styles: {},
  error: {},
  label: '',
  small: false,
};

Radio.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
  }),

  name: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  /** a cute small radio button */
  small: PropTypes.bool,
  style: PropTypes.object,
  checked: PropTypes.bool,
  error: PropTypes.shape({
    hasError: PropTypes.bool,
  }),
  'data-testid': PropTypes.string,
  /** ADA related - you should wrap Radio in a RadioGroup and let it handle this! */
  'aria-checked': PropTypes.bool,
  /** ADA related - you should wrap Radio in a RadioGroup and let it handle this! */
  tabIndex: PropTypes.number,
};

export default Radio;
