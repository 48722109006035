import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';
import styles from './chip.css';

function Chip({
  label,
  checked,
  icon,
  classes,
  error: { hasError, errorMessage },
  ...rest
}) {
  const rootClassName = classNames(Typography.legalBody, styles.root, classes.root, {
    [styles.checked]: checked,
    [styles.error]: hasError || errorMessage,
  });

  return (
    <button className={rootClassName} aria-checked={checked} {...rest}>
      {icon ? <img src={icon} alt='' className={classes.img} /> : null}

      <span className={classes.label}>{label}</span>
    </button>
  );
}

Chip.defaultProps = {
  classes: {},
  checked: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

Chip.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    img: PropTypes.string,
    label: PropTypes.string,
  }),

  label: PropTypes.node,
  icon: PropTypes.string,
  checked: PropTypes.bool,
  error: PropTypes.shape({
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),

  // spread via ...rest
  role: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  'data-testid': PropTypes.string,
};

export default Chip;
