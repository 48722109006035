import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';
import styles from './checkbox.css';

const variantTypography = {
  body1: Typography.body1,
  legalBody: Typography.legalBody,
};

const noop = () => {};

function Checkbox({
  classes,
  error: { hasError },
  style,
  label,
  onChange,
  variant,
  disabled,
  'data-testid': dataTestId,
  ...restInputProps
}) {
  return (
    <label
      className={classNames(styles.root, classes.root, {
        [styles.disabled]: disabled,
      })}
      style={style}
      onChange={onChange}
      data-testid={dataTestId}
      disabled={disabled}
    >
      <div className={styles.inputs}>
        <input
          className={styles.checkbox}
          type='checkbox'
          onChange={noop}
          data-is-error={!!hasError}
          disabled={disabled}
          {...restInputProps}
        />
        <div
          className={classNames(styles.image, {
            [styles.error]: hasError,
          })}
          aria-hidden
        />
      </div>
      <span className={classNames(styles.label, variantTypography[variant])}>
        {label}
      </span>
    </label>
  );
}

Checkbox.defaultProps = {
  classes: {},
  styles: {},
  error: {},
  label: null,
  variant: 'legalBody',
  disabled: false,
};

Checkbox.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.node,
  onChange: PropTypes.func,
  style: PropTypes.object,
  large: PropTypes.bool,
  'data-testid': PropTypes.string,
  variant: PropTypes.oneOf(['body1', 'legalBody']),

  // props for restInputProps autocomplete
  disabled: PropTypes.bool,
  name: PropTypes.string,
  checked: PropTypes.bool,
  'aria-checked': PropTypes.bool,
  value: PropTypes.any,
};

export default Checkbox;
